import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { Add } from '@material-ui/icons'
import mixpanel from '../../shared-components/utils/mixpanel'
import { resetDraft, setDraft, setLocBeforeEdit } from '../../actions/event'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { setCurrentScrapeBotId } from 'actions/scraper'
import { getIsMobileOrTablet } from 'utils/isMobileOrTablet'
import cn from 'classnames'
const useStyles = makeStyles((theme) => ({
  root: {},
  menuItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  menuRoot: {
    width: '100%',
    maxWidth: '359px',
    [theme.breakpoints.down('sm')]: {
      left: '0 !important',
    },
  },
  menuList: {
    padding: 0,
  },
  mainButton: {
    cursor: 'pointer',
    color: '#754AA1',
  },
  buttonText: {
    font: 'SF Pro Text',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19.09px',
    letterSpacing: '0.5px',
  },
  menuItemRoot: {
    padding: theme.spacing(1, 0, 1, 1),
    flexGrow: 1,
  },
  menuItemIcon: {
    marginRight: theme.spacing(2),
  },
  menuDivider: {
    width: '100%',
    height: 1,
    background: theme.palette.gray.main,
  },
  menuPrimaryItem: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  menuSecondaryContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  menuSecondaryItem: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    alignItems: 'center',
    padding: '9px 30px 9px 0px',
  },
  menuItemDivider: {
    width: '1px',
    height: '40px',
    background: theme.palette.gray.main,
    marginRight: '29px',
  },
  newEventButton: {
    height: '32px',
    padding: theme.spacing(1, 1.5),
    color: theme.palette.secondary[800],
    backgroundColor: 'white',
  },
  mobileIcon: {
    color: theme.palette.gray[700],
    cursor: 'pointer',
  },
  '@keyframes gradientFlow': {
    '0%': {
      backgroundPosition: '0% center',
    },
    '50%': {
      backgroundPosition: '100% center',
    },
    '100%': {
      backgroundPosition: '200% center',
    },
  },
  gradientText: {
    background:
      'linear-gradient(90deg, #F33DF3 0%, #9B40F9 25%, #3D44F7 50%, #3F6AD4 75%, #F33DF3 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
    backgroundSize: '200% auto',
    backgroundPosition: '0% center',
    animation: '$gradientFlow 6s ease infinite',
    padding: 0,
  },
  smallIcon: {
    width: 14,
    height: 14,
    color: theme.palette.secondary[800],
  },
  fancyStartIcon: {
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    fontWeight: 500,
    fontSize: '12px',
    height: '14px',
  },
}))

const CreateButton = ({
  mobile = false,
  basic = false,
  className,
  fancy = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const menuOpen = Boolean(menuAnchorEl)
  const router = useRouter()
  const dispatch = useDispatch()
  const draft = useSelector((state) => state.event.events.draft)
  const currentUser = useSelector((state) => state.event.currentSelectedUser)
  const orgUsername = router.query?.username
  const currentOrg = useSelector((state) => state.organisation.organisation)

  const onClick = (e) => {
    // TODO: Mixpanel
    onEventClick()
    // setMenuAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setMenuAnchorEl(null)
  }

  const onItemClick = (type) => {
    switch (type) {
      case 'EVENT':
        mixpanel.track('Start Throw Bash', {
          'Location in App': 'header',
          Path: window.location.pathname,
        })
        dispatch(resetDraft())
        changeDraft()
        dispatch(setLocBeforeEdit(router.asPath))
        dispatch(setCurrentScrapeBotId(null))
        router.push('/create', undefined, { shallow: true })
        break
      case 'PAGE':
        mixpanel.track('Create Organisation', {
          'Location in App': 'header',
          Path: window.location.pathname,
        })
        router.push('/public/create')
        break
    }

    handleClose()
  }

  const changeDraft = () => {
    if (orgUsername) {
      // Page
      dispatch(
        setDraft({
          ...draft,
          organisation: currentOrg,
          hosts: [
            {
              type: 'ORGANISATION',
              model: currentOrg,
            },
          ],
          privacyType: currentOrg.privateProfile ? 'FOLLOWERS' : 'PUBLIC',
          madeChange: false,
        }),
      )
    } else if (currentUser?.id === 0) {
      // Personal account
      dispatch(
        setDraft({
          ...draft,
          type: 'INVITE',
          organisation: null,
          privacyType: 'PRIVATE',
          madeChange: false,
        }),
      )
    } else {
      // Page
      dispatch(
        setDraft({
          ...draft,
          organisation: currentUser,
          hosts: [
            {
              type: 'ORGANISATION',
              model: currentUser,
            },
          ],
          privacyType: currentUser.privateProfile ? 'FOLLOWERS' : 'PUBLIC',
          madeChange: false,
        }),
      )
    }
  }

  const onEventClick = () => onItemClick('EVENT')

  if (mobile || basic) {
    return (
      <Add
        style={{}}
        className={cn(classes.mobileIcon, className)}
        onClick={onClick}
      />
    )
  }

  return (
    <FlatButton
      onClick={onClick}
      className={cn(classes.newEventButton, className, {
        [classes.gradientText]: fancy,
        [classes.fancyStartIcon]: fancy,
      })}
      startIcon={
        getIsMobileOrTablet() || fancy ? (
          false
        ) : (
          <Add
            className={cn({ [classes.smallIcon]: fancy })}
            color={fancy ? 'secondary' : 'inherit'}
          />
        )
      }
      color='white'
    >
      {fancy ? t('common:createYourOwnEvent') : t('common:createEvent')}
    </FlatButton>
  )
}

export default CreateButton
