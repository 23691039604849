import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  makeStyles,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import cn from 'clsx'

import Logo from '../../svg/logo/IconBoxed'
import TextLogo from '../../svg/TextLogo'
import { useDownloadApp } from '../../shared-components/utils/downloadApp'
import FlatButton from '../../shared-components/buttons/FlatButton'
import theme from '../../shared-components/theme'
import AppleIcon from '@material-ui/icons/Apple'
import { Android } from '@material-ui/icons'
import AndroidIcon from '../../svg/AndroidIcon'
import CreateButton from 'components/Navigation/CreateButton'
import { mdiInstagram, mdiLinkedin, mdiWhatsapp } from '@mdi/js'
import { useIsLoggedIn } from 'utils/hooks'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
    maxWidth: theme.containerMaxWidth,
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  logo: {
    width: 14.44,
    height: 15,
    color: theme.palette.grey[700],
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  textLogo: {
    height: 13.52,
    color: theme.palette.grey[700],
  },
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 8),
    height: 48,
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    textAlign: 'center',
    color: theme.palette.grey[600],
  },
  downloadText: {
    color: theme.palette.grey[700],
  },
  storeButtonsContainer: {
    fill: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  storeButtonsDivider: {
    height: 14,
    width: 1,
    borderRadius: '100px',
    backgroundColor: theme.palette.grey[600],
  },
  linksContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    color: theme.palette.grey[600],
    '& a': {
      textDecoration: 'underline',
    },
    flexWrap: 'wrap',
  },
  createButton: {},
  storeButtonsIcon: {
    fill: theme.palette.grey[600],
    width: 14,
    height: 14,
  },
  copyRightContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  copyRightContainerMobile: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  leftContainerMobile: {
    alignItems: 'center',
  },
  socialsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  socialsIcon: {
    width: 18,
    height: 18,
    color: theme.palette.grey[700],
  },
  tiktokIcon: {
    width: 14,
    height: 14,
    color: theme.palette.grey[700],
  },
  darkGrey: {
    color: theme.palette.grey[700],
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: theme.spacing(1.5),
  },
  rightContainerMobile: {
    alignItems: 'center',
  },
}))

const GeneralFooter = ({ maxWidth }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const desktopMode = useMediaQuery('(min-width:900px)')

  const isLoggedIn = useIsLoggedIn()
  const androidClicked = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.vlinderstorm.bash',
    )
  }
  const appleClicked = () => {
    window.open('https://apps.apple.com/app/bash-sociale-events/id1448137786')
  }

  const copyRight = () => {
    return (
      <div
        className={cn(
          classes.copyRightContainer,
          !desktopMode && classes.copyRightContainerMobile,
        )}
      >
        <Typography variant='caption' className={classes.text}>
          © BASH copyright and trademark 2024
        </Typography>
        <div className={classes.storeButtonsContainer}>
          <AppleIcon
            className={classes.storeButtonsIcon}
            onClick={appleClicked}
          />
          <div className={classes.storeButtonsDivider} />
          <AndroidIcon
            className={classes.storeButtonsIcon}
            onClick={androidClicked}
          />
        </div>
      </div>
    )
  }

  const showSupport = () => {
    window.open('https://wa.me/message/4GG2EGM4GIDBD1')
  }

  return (
    <div className={classes.container}>
      <div
        className={cn(classes.root, !desktopMode && classes.rootMobile)}
        style={{ maxWidth }}
      >
        <div
          className={cn(
            classes.leftContainer,
            !desktopMode && classes.leftContainerMobile,
          )}
        >
          <div className={classes.logoContainer}>
            <Logo className={classes.logo} />
            <TextLogo className={classes.textLogo} />
          </div>

          <Typography variant='caption' className={classes.downloadText}>
            Where hype starts
          </Typography>
          {desktopMode && copyRight()}
        </div>

        {!desktopMode && (
          <CreateButton fancy className={classes.createButton} />
        )}

        <div
          className={cn(
            classes.rightContainer,
            !desktopMode && classes.rightContainerMobile,
          )}
        >
          <div className={classes.socialsContainer}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/bash.social/'
            >
              <SvgIcon className={classes.socialsIcon}>
                <path d={mdiInstagram} />
              </SvgIcon>
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.tiktok.com/@bash.social'
            >
              <img
                src='/images/tiktok_grey.svg'
                className={classes.tiktokIcon}
              />
            </a>

            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://linkedin.com/company/bashsocial'
            >
              <SvgIcon className={classes.socialsIcon}>
                <path d={mdiLinkedin} />
              </SvgIcon>
            </a>

            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://wa.me/message/4GG2EGM4GIDBD1'
            >
              <SvgIcon className={classes.socialsIcon}>
                <path d={mdiWhatsapp} />
              </SvgIcon>
            </a>
          </div>
          <div className={cn(classes.linksContainer, classes.darkGrey)}>
            {desktopMode && (
              <CreateButton fancy className={classes.createButton} />
            )}
            {isLoggedIn && (
              <Typography variant='caption'>
                <a href='/home'>My events</a>
              </Typography>
            )}
            {isLoggedIn && (
              <Typography variant='caption'>
                <a href='/foryou'>For you</a>
              </Typography>
            )}
            {!isLoggedIn && (
              <Typography variant='caption'>
                <a href='/foryou'>Hot events</a>
              </Typography>
            )}
            {!isLoggedIn && (
              <Typography variant='caption'>
                <a href='/signIn'>Sign in</a>
              </Typography>
            )}
          </div>
          <div className={classes.linksContainer}>
            <Typography variant='caption'>
              <a href='/about'>About</a>
            </Typography>
            <Typography variant='caption'>
              <a href='/jobs'>Jobs</a>
            </Typography>
            <Typography variant='caption'>
              <a href='/pricing'>Pricing</a>
            </Typography>
            <Typography variant='caption'>
              <a href='/privacy'>Privacy</a>
            </Typography>
            <Typography variant='caption'>
              <a href='/terms'>Terms & conditions</a>
            </Typography>
            <Typography variant='caption'>
              <a onClick={() => showSupport()}>Support</a>
            </Typography>
          </div>

          {!desktopMode && copyRight()}
        </div>
      </div>
    </div>
  )
}
export default GeneralFooter
