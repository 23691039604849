import React from 'react'

const AndroidIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_2208_33787)'>
      <path d='M5.00033 15C5.00033 15.4583 5.37533 15.8333 5.83366 15.8333H6.66699V18.75C6.66699 19.4417 7.22533 20 7.91699 20C8.60866 20 9.16699 19.4417 9.16699 18.75V15.8333H10.8337V18.75C10.8337 19.4417 11.392 20 12.0837 20C12.7753 20 13.3337 19.4417 13.3337 18.75V15.8333H14.167C14.6253 15.8333 15.0003 15.4583 15.0003 15V6.66667H5.00033V15ZM2.91699 6.66667C2.22533 6.66667 1.66699 7.225 1.66699 7.91667V13.75C1.66699 14.4417 2.22533 15 2.91699 15C3.60866 15 4.16699 14.4417 4.16699 13.75V7.91667C4.16699 7.225 3.60866 6.66667 2.91699 6.66667ZM17.0837 6.66667C16.392 6.66667 15.8337 7.225 15.8337 7.91667V13.75C15.8337 14.4417 16.392 15 17.0837 15C17.7753 15 18.3337 14.4417 18.3337 13.75V7.91667C18.3337 7.225 17.7753 6.66667 17.0837 6.66667ZM12.942 1.8L14.0253 0.716667C14.192 0.55 14.192 0.291667 14.0253 0.125C13.8587 -0.0416667 13.6003 -0.0416667 13.4337 0.125L12.2003 1.35833C11.542 1.025 10.792 0.833333 10.0003 0.833333C9.20033 0.833333 8.45033 1.025 7.78366 1.35833L6.54199 0.125C6.37533 -0.0416667 6.11699 -0.0416667 5.95033 0.125C5.78366 0.291667 5.78366 0.55 5.95033 0.716667L7.04199 1.80833C5.80866 2.71667 5.00033 4.175 5.00033 5.83333H15.0003C15.0003 4.175 14.192 2.70833 12.942 1.8ZM8.33366 4.16667H7.50033V3.33333H8.33366V4.16667ZM12.5003 4.16667H11.667V3.33333H12.5003V4.16667Z' />
    </g>
    <defs>
      <clipPath id='clip0_2208_33787'>
        <rect width='20' height='20' />
      </clipPath>
    </defs>
  </svg>
)

export default AndroidIcon
